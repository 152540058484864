html{
  padding-left: calc(100vw - 100%);
}

body {
  font-family: "Poppins", sans-serif;
}

.badge {
  font-weight: 500;
}
